<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Cecos y Afes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operacion</li>
                  <li class="breadcrumb-item active">Cecos y Afes</li>
                  <li class="breadcrumb-item active">
                    Formulario Cecos y Afes
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5 class="mb-0">Formulario asignar responsable</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-2">
                        <label for="nombres">Codigo Contable</label>
                        <input
                          type="text"
                          disabled
                          class="form-control form-control-sm"
                          id="nombres"
                          v-model="form.codigo_contable"
                          @input="validarLetra(form.codigo_contable, 1)"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="nombres">Tipo De Presupuesto</label>
                        <input
                          type="text"
                          disabled
                          class="form-control form-control-sm"
                          id="nombres"
                          v-model="form.tipo_presupuesto"
                          @input="validarLetra(form.tipo_presupuesto, 1)"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="marca"> Orden Interna</label>
                        <input
                          type="text"
                          disabled
                          class="form-control form-control-sm"
                          id="nombres"
                          v-model="form.orden_interna"
                          @input="validarLetra(form.orden_interna, 1)"
                        />
                      </div>
                      <div class="form-group col-md-5">
                        <label for="nombres">Alianza Estrategica</label>
                        <input
                          type="text"
                          disabled
                          class="form-control form-control-sm"
                          id="nombres"
                          v-model="form.alianza_estrategica"
                          @input="validarLetra(form.alianza_estrategica, 1)"
                        />
                      </div>
                      <div class="form-group col-md-5">
                        <label for="nombres">Descripcion</label>
                        <input
                          type="text"
                          disabled
                          class="form-control form-control-sm"
                          id="nombres"
                          v-model="form.descripcion"
                          @input="validarLetra(form.descripcion, 1)"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="nombres">Responsable</label>
                        <input
                          type="text"
                          disabled
                          class="form-control form-control-sm"
                          id="nombres"
                          v-model="form.responsable"
                          @input="validarLetra(form.responsable, 1)"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="nombres">Chequera</label>
                        <select
                          class="form-control form-control-sm"
                          disabled
                          v-model="form.chequera"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="chequera in listasForms.chequeras"
                            :key="chequera.numeracion"
                            :value="chequera.numeracion"
                          >
                            {{ chequera.descripcion }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-3">
                        <label for="nombres">Presupuesto</label>
                        <input
                          type="text"
                          disabled
                          class="form-control form-control-sm"
                          id="nombres"
                          v-model="form.presupuesto"
                          @input="validarLetra(form.presupuesto, 1)"
                        />
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Funcionario Responsable</label>
                          <v-select
                            :class="[
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                              $v.form.funcionario_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                            ]"
                            class="form-control form-control-sm p-0"
                            v-model="funcionario"
                            :options="listasForms.funcionarios"
                            label="nombres"
                            @input="getSelectFuncionario()"
                          >
                          </v-select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8 offset-md-2">
                        <CecosYafesLinea
                          v-if="
                            form.id != null &&
                              $store.getters.can(
                                'admin.cecosYafes.assingOrRevokeLinea'
                              )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <button
                  class="btn bg-primary col-md-1"
                  v-show="!$v.form.$invalid"
                  @click="save()"
                >
                  <i class="fas fa-paper-plane"></i><br />Guardar
                </button>
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i><br />Volver
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import CecosYafesLinea from "./CecosYafesLinea";
import vSelect from "vue-select";

export default {
  name: "CecosYafesForm",
  components: {
    Loading,
    vSelect,
    CecosYafesLinea,
  },
  data() {
    return {
      opcion: 1,
      cargando: false,
      form: {
        funcionario_id: null,
        codigo_contable: null,
        tipo_presupuesto: null,
        orden_interna: null,
        alianza_estrategica: null,
        descripcion: null,
        responsable: null,
        chequera: null,
        presupuesto: null,
      },
      funcionario: null,
      accion: "",
      metodo: "",
      listasForms: {
        tipo_presupuesto: [],
        chequeras: [],
        funcionarios: [],
      },
      id: this.$route.params.id,
    };
  },
  validations: {
    form: {
      funcionario_id: [],
    },
  },
  methods: {
    async getFuncionarios() {
      await axios.get("/api/admin/funcionarios/lista").then((response) => {
        this.listasForms.funcionarios = response.data;
      });
    },
    getSelectFuncionario() {
      this.form.funcionario_id = "";
      this.form.funcionario = {};

      if (this.funcionario) {
        this.form.funcionario_id = this.funcionario.id;
        this.form.funcionario = this.funcionario;
      }
    },
    save() {
      if (this.form.tipo_presupuesto == "Cecos") {
        this.form.tipo_presupuesto = 1;
      }
      if (this.form.tipo_presupuesto == "Afes") {
        this.form.tipo_presupuesto = 2;
      }

      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/cecosYafes",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.$swal({
              icon: "success",
              title: "El funcionario responsable se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    getTiposChequera() {
      axios.get("/api/lista/140").then((response) => {
        this.listasForms.chequeras = response.data;
      });
    },

    back() {
      return this.$router.replace("/Admin/CecosYafes");
    },

    init() {
      this.accion = this.$route.params.accion;
      this.opcion = this.$route.params.opcion;

      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.CecosYafes;
        this.metodo = "PUT";

        if (this.form.funcionario != null) {
          this.funcionario = {
            id: this.form.funcionario_id,
            nombres:
              this.form.funcionario.nombres +
              " " +
              this.form.funcionario.apellidos,
          };

          this.form.funcionario_id = this.funcionario.id;
        }

        if (this.form.tipo_presupuesto == 1) {
          this.form.tipo_presupuesto = "Cecos";
        }
        if (this.form.tipo_presupuesto == 2) {
          this.form.tipo_presupuesto = "Afes";
        }
      }
    },
  },
  computed: {
    logo() {
      return this.miniLogo;
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getFuncionarios();
    await this.init();
    await this.getTiposChequera();
    this.cargando = false;
  },
  created() {},
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
